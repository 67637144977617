.tenant-popover .mantine-Popover-dropdown {
  /* transform: translateX(10%); */
}
.mantine-Drawer-drawer {
  border-top-left-radius: 70px;
  padding: 70px 0;
}
.mantine-Drawer-header {
  padding: 0 24px;
}
.drawer-form li input:focus-visible + span {
  @apply border-blue-500;
}

