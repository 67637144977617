@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Muli';
  src: local('Muli'), url('./Assets/fonts/Muli-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli';
  src: url('./Assets/fonts/Muli-Regular.ttf') format('truetype');
}

*,
*::before,
*::after {
  font-family: 'Muli' !important;
}

@media (max-width: 1200px) {
  html,
  body {
    font-size: 12px !important;
  }
}
