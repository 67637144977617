::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 40px;
  background-color: rgb(233, 230, 230);
}
html.dark ::-webkit-scrollbar-thumb {
  @apply bg-wdark-300;
}
html.dark ::selection {
  background-color: #b0b7c3;
  color: #4b5568;
}
body {
  @apply !text-textgrey dark:!text-wdark-50 transition-all duration-200;
}
html.dark .mantine-Paper-root {
  background-color: #161c27 !important;
  color: #b0b7c3 !important;
}
html.dark .mantine-Modal-title {
  color: #b0b7c3 !important;
}
/* html.dark .mantine-UnstyledButton-root:hover {
  background-color: #4b5568;
} */
.sidebar a[aria-current='page'] svg path {
  fill: #38cb89;
}

.mantine-Modal-modal {
  padding: 0;
  border-radius: 1.75rem;
}
.mantine-Modal-overlay,
.mantine-Drawer-overlay {
  background-color: rgba(50, 59, 75, 0.8);
}
.mantine-Modal-header {
  color: #54565b;
  font-size: 1.25rem;
  text-transform: capitalize;
  padding: 2rem 2rem 1rem 2rem;
}
.mantine-Pagination-item {
  border: none;
}
.mantine-Modal-body {
  @apply dark:border-t-wdark-300;
}
.mantine-Pagination-item[data-active] {
  background-color: transparent;
  color: #38cb89;
}
.mantine-Calendar-day[data-selected='true'] {
  background-color: #38cb89;
}
.mantine-Pagination-item:first-child,
.mantine-Pagination-item:last-child {
  background-color: #38cb89;
  color: #fff;
}
.mantine-Popover-dropdown {
  display: flex;
  width: 600px;
  flex-direction: column;
  gap: 10px;
  background-color: #f9fafb;
  border-color: #38cb89;
  padding: 15px;
  border-radius: 10px;
}
.mantine-Popover-dropdown {
  @apply dark:border-wdark-400 dark:bg-wdark-500;
}
.mantine-Progress-root {
  background-color: #eeefee;
}

.mantine-Tooltip-tooltip {
  background: rgba(84, 86, 91, 0.84);
  padding: 10px;
}

li.selected {
  color: #38cb89;
}
li.previous.disabled svg,
li.next.disabled svg {
  background-color: #f3f3f3;
  color: #9fa19c;
  cursor: not-allowed;
}
html.dark .checkbox.checkbox.white::before,
html.dark .checkbox::before {
  background-color: #b0b7c3;
}
.checkbox {
  appearance: none;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.checkbox::before {
  content: '';
  position: absolute;
  left: 0;
  transform: translateY(12%);
  height: 22px;
  width: 22px;
  background-color: #f9f9fb;
  border-radius: 5px;
}
.checkbox.white::before {
  background-color: #fff;
}
.checkbox:checked::before {
  background-color: #38cb89;
  content: url('./Assets/check.svg');
  padding-top: 3px;
  padding-left: 5px;
}

